import React from 'react'
import {
  Theme,
  Typography,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '0.625rem',
      [up('xl')]: {
        marginBottom: '1.3125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Subtitle = ({ classes, children }: Props) => {
  return (
    <Typography variant="subtitle1" classes={classes} component="h2">
      {children}
    </Typography>
  )
}

export default withStyles(styles)(Subtitle)
