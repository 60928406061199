import React from 'react'
import { Button, withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import { FlexItemInfo, getMarginBottomGenerator } from '../../../utils/flex'
import { LinkInfo } from './types'

const styles = ({ breakpoints: { up } } : Theme) => {
  const md = up('md')
  return createStyles({
    root: {
      listStyle: 'none',
      flexBasis: '50%',
      marginBottom: getMarginBottomGenerator(2, '2.875rem'),
      [md]: {
        flexBasis: '25%',
        marginBottom: getMarginBottomGenerator(4, '2.4375rem'),
      },
    },
    button: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      textDecoration: 'underline',
      padding: 0,
      [md]: {
        fontSize: '1.5625rem',
        lineHeight: '2.1875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, FlexItemInfo, LinkInfo {}

const Link = ({ classes, children, href }: Props) => {
  return (
    <li className={classes.root}>
      <Button href={href} className={classes.button}>
        {children}
      </Button>
    </li>
  )
}

export default withStyles(styles)(Link)
