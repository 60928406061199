import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ProjectPreview } from '../../types/Project'
import { handleImgNotFoundError } from '../../utils/errors'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: '1.375rem',
      [theme.breakpoints.up('xl')]: {
        marginBottom: '2.1875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, ProjectPreview {}

const Image = ({ classes, title, thumbnail }: Props) => {
  const image = getImage(thumbnail.localFile)
  if (!image) return handleImgNotFoundError()
  return <GatsbyImage image={image} className={classes.root} alt={title} />
}

export default withStyles(styles)(Image)
