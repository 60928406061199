import React, { ComponentClass, FunctionComponent } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import { flexBreakpoint } from '../Project/styles'
import { ProjectPreview } from '../../types/Project'

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  return createStyles({
    root: {},
    list: {
      marginLeft: 0,
      paddingLeft: 0,
      [flexBreakpointUp]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    },
  })
}

interface Props<ProjectType> extends WithStyles<typeof styles> {
  projects: ProjectType[]
  Project: FunctionComponent<ProjectType> | ComponentClass<ProjectType>
}

const getList = <ProjectType extends ProjectPreview>() => {
  const List = ({ classes, projects, Project }: Props<ProjectType>) => {
    const renderItem = (project: ProjectType): JSX.Element => (
      <Project {...project} key={project.slug} />
    )

    const renderedProjects: JSX.Element[] = projects.map(renderItem)

    return (
      <div className={classes.root}>
        <ul className={classes.list}>{renderedProjects}</ul>
      </div>
    )
  }

  return withStyles(styles)(List)
}

export default getList
