import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../PageContainer'
import Toolbar from './Toolbar'
import Links from './Links'
import { getFlexBreakpoint } from './styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(up)
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '4.3125rem',
      [up('md')]: {
        marginBottom: '4.9375rem',
      },
      [up('lg')]: {
        marginBottom: '5rem',
      },
      [xl]: {
        marginBottom: '7.5rem',
      },
    },
    container: {
      [flexBreakpoint]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Social = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        <Toolbar />
        <Links />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Social)
