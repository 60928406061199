import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import Toolbar from './Toolbar'
import List from './List'
import Testimonials from '../components/Testimonials'
import Social from '../components/Social'
import Contact from '../components/Contact'
import { ProjectsQuery } from '../types/Project'
import Seo from '../components/Seo'

const Portfolio = () => {
  const data: ProjectsQuery = useStaticQuery(graphql`
    query ProjectPreview {
      allStrapiProjects(sort: { order: ASC, fields: order }) {
        nodes {
          slug
          title
          description
          url
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Portfolio" />
      <Toolbar />
      <List projects={data.allStrapiProjects.nodes} />
      <Testimonials />
      <Social />
      <Contact />
    </Layout>
  )
}

export default Portfolio
