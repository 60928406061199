import { withStyles, createStyles, Theme } from '@material-ui/core'

import BaseProject from '../Project'

const styles = ({ breakpoints: { between } }: Theme) => {
  return createStyles({
    root: {
      display: 'block',
      '& + &': {
        [between('xs', 'sm')]: {
          marginTop: '2.0625rem',
        },
      },
    },
  })
}

const ProjectOfProjectsList = withStyles(styles)(BaseProject)

export default ProjectOfProjectsList
