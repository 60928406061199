export const getStylesForBreakpoint = (marginX: string, marginY: string) => {
  const width: string = `calc(50% - ${marginX} / 2)`
  return {
    width,
    flexBasis: width,
    '&:nth-of-type(odd)': {
      marginTop: marginY,
    },
    '&:nth-of-type(even)': {
      marginBottom: marginY,
    },
  }
}
