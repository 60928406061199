import React from 'react'
import BaseTitle from '../../SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    Got a project?
    <br />
    Let&apos;s Connect
  </BaseTitle>
)

export default Title
