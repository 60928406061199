import React from 'react'
import BaseTitle from '../../components/SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    The work I do,
    <br />
    and business I help.
  </BaseTitle>
)

export default Title
