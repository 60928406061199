import React from 'react'
import { Container, Theme } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'

import { Project, ProjectPreview } from '../../types/Project'
import getList from '../../components/ProjectsList'
import ProjectOfProjectsList from '../../components/ProjectsList/Project'

const BaseList = getList<ProjectPreview>()

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '5.875rem',
      [up('lg')]: {
        marginBottom: '10rem',
      },
      [up('xl')]: {
        marginBottom: '15.5rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  projects: Project[]
}

const ProjectList = (props: Props) => {
  return (
    <Container component="section">
      <BaseList Project={ProjectOfProjectsList} {...props} />
    </Container>
  )
}

export default withStyles(styles)(ProjectList)
