import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import SectionToolbar from '../../components/SectionToolbar'
import Title from './Title'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginTop: '2.75rem',
      marginBottom: '2.8125rem',
      [up('lg')]: {
        marginTop: '10.625rem',
        marginBottom: '6.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Toolbar = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <SectionToolbar title={<Title />} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Toolbar)
