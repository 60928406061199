import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { Link } from 'gatsby'

import { ProjectPreview } from '../../types/Project'
import Image from './Image'
import Toolbar from './Toolbar'
import { flexBreakpoint } from './styles'
import { getStylesForBreakpoint } from './getStylesForBreakpoint'
import { createProjectLink } from '../../utils/links'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      textDecoration: 'none',
      [up(flexBreakpoint)]: {
        ...getStylesForBreakpoint('0.875rem', '2.375rem'),
      },
      [up('lg')]: {
        ...getStylesForBreakpoint('2.75rem', '3.3125rem'),
      },
    },
  })
}

/**
 * We must export this to prevent ts(4023) in some files.
 */
export interface Props extends WithStyles<typeof styles>, ProjectPreview {
  toolbar?: React.ReactNode
}

const ProjectItem = ({ classes, toolbar = null, ...project }: Props) => {
  const { slug } = project

  const content: React.ReactFragment = (
    <>
      <Image {...project} />
      {toolbar || <Toolbar {...project} />}
    </>
  )

  return (
    <Link to={createProjectLink(slug)} className={classes.root}>
      {content}
    </Link>
  )
}

export default withStyles(styles)(ProjectItem)
