import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import SectionToolbar from '../../SectionToolbar'
import Title from './Title'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3.125rem',
      [up('md')]: {
        marginBottom: '2.875rem',
      },
      [up('lg')]: {
        marginBottom: 0,
        marginRight: '8rem',
      },
      [up('xl')]: {
        marginRight: '11.1875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Toolbar = ({ classes }: Props) => {
  return <SectionToolbar title={<Title />} classes={classes} />
}

export default withStyles(styles)(Toolbar)
