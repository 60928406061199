import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Subtitle from './Subtitle'

const styles = (theme: Theme) => {
  const md = theme.breakpoints.up('md')
  return createStyles({
    root: {
      marginBottom: '2.8125rem',
    },
    divider: {
      width: '2.875rem',
      height: '0.3125rem',
      backgroundColor: '#F9944C',
      borderRadius: '0.2188rem',
      [md]: {
        width: '3.4375rem',
        height: '0.375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: React.ReactChild
  subtitle?: string
}

const SectionToolbar = ({ classes, title, subtitle }: Props) => {
  return (
    <div className={classes.root}>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {title}
      <div className={classes.divider} />
    </div>
  )
}

export default withStyles(styles)(SectionToolbar)
