import React from 'react'
import {
  Theme,
  Typography,
  TypographyProps,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '0.4375rem',
      [md]: {
        marginBottom: '0.625rem',
      },
      [xl]: {
        marginBottom: '1.0625rem',
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<TypographyProps, 'classes'> {}

const Title = (props: Props) => {
  return <Typography display="block" variant="h2" {...props} />
}

export default withStyles(styles)(Title)
