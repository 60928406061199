export interface FlexItemInfo {
  index: number
  itemsNumber: number
}

/**
 * Generates a margin bottom for a flex item, where the list has multiple rows.
 * Assigns margin bottom to all elements except for the ones in the last row.
 */
export const getMarginBottomGenerator = (
  columns: number,
  marginBottom: string
) => {
  return ({ itemsNumber, index }: FlexItemInfo): number | string => {
    const itemsRemainder: number = itemsNumber % columns
    const bottomItems: number = itemsRemainder === 0 ? columns : itemsRemainder
    const firstBottomItemIndex: number = itemsNumber - bottomItems
    const isBottomItem: boolean = index >= firstBottomItemIndex
    return isBottomItem ? 0 : marginBottom
  }
}

/**
 * Generates a margin left for a flex item, where the list has multiple rows.
 * Assigns margin left to all items that have a left item in the same row.
 */
export const getMarginLeftGenerator = (margin: string, columns: number) => ({
  index,
}: FlexItemInfo): string | number => {
  const isFirstInRow: boolean = index % columns === 0
  return isFirstInRow ? 0 : margin
}

export const getWidth = (margin: string, columns: number) => {
  const gaps: number = columns - 1
  const totalMargin: string = `calc(${margin} * ${gaps})`
  const itemMargin: string = `calc(${totalMargin} / ${columns})`
  return `calc(100% / ${columns} - ${itemMargin})`
}

export const getWidthAndMarginLeftGenerators = (
  margin: string,
  columns: number
) => {
  const width = getWidth(margin, columns)
  return {
    width: width,
    marginLeft: getMarginLeftGenerator(margin, columns),
    flexBasis: width,
  }
}
