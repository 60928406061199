import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Link from './Link'
import { getFlexBreakpoint } from '../styles'
import { LinkInfo } from './types'
import social from '../../../config/social'

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(up)
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: 0,
      marginBottom: 0,
      flexWrap: 'wrap',
      [up('md')]: {
        justifyContent: 'space-between',
      },
      [flexBreakpoint]: {
        flexGrow: 1,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Links = ({ classes }: Props) => {
  const links: LinkInfo[] = [
    {
      href: social.github,
      children: 'GitHub',
    },
    {
      href: 'mailto:eduardo@supercoder.dev',
      children: 'Email',
    },
    {
      href: social.linkedIn,
      children: 'LinkedIn',
    },
    {
      href: 'https://www.fiverr.com/alex448800?public_mode=true',
      children: 'Fiverr',
    },
    {
      href: 'https://www.upwork.com/o/profiles/users/~0161a199cdecadce49/',
      children: 'Upwork',
    },
    {
      href: 'https://medium.com/@8025918',
      children: 'Medium',
    },
  ]

  const renderLink = (linkInfo: LinkInfo, index: number): JSX.Element => {
    return (
      <Link
        {...linkInfo}
        key={index}
        index={index}
        itemsNumber={links.length}
      />
    )
  }

  const renderedLinks: JSX.Element[] = links.map(renderLink)

  return <ul className={classes.root}>{renderedLinks}</ul>
}

export default withStyles(styles)(Links)
